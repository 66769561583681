// Load vue.js
import Vue from "vue";

// Load main application
import App from "./App.vue";

// Laoad Vuetify
// import vuetify from "./plugins/vuetify";
import vuetify from "./plugins/vuetify-loader";

// Load vue router
import router from "./router";

// load vue-echarts
import "./plugins/echarts";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <img class="logo" src="../assets/drs_logo.png" />
          Login
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="pinNumber"
              :counter="5"
              :rules="nameRules"
              label="PIN Code"
              required
            ></v-text-field>
            <span class="errormsg">{{ message }}</span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              @click="login()"
              :style="{ left: '50%', transform: 'translateX(-50%)' }"
            >
              Submit
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../plugins/api.js";

export default {
  name: "login-view",
  data() {
    return {
      title: "TODO: Description what's shown on this site",
      dialog: true,
      pinNumber: null,
      message: null,
    };
  },
  methods: {
    login: function() {
      var _this = this;

      api
        .requestLogin(this.pinNumber)
        .then(function() {
          _this.$router.push({ path: "/flights" });
        })
        .catch(function(error) {
          console.log("ERROR requestLogin '" + error.message + "'");

          if (error.response.status == 403) {
            _this.message = "Wrong PIN code";
          }
        });
    },
  },
};
</script>

<style scoped>
.logo {
  margin-right: 10px;
}

.errormsg {
  color: red;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-data-table',{staticClass:"flight-scenarios",attrs:{"hide-default-footer":true,"headers":_vm.headers,"items":_vm.flights,"items-per-page":50,"height":"800","fixed-header":"","item-key":"sequence","group-by":"category"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"14"}},[_c('v-icon',{staticStyle:{"margin-right":"10px"},attrs:{"text":""},on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_vm._v(" "+_vm._s(items[0].category)+" ")],1)]}},{key:"item.scenario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.scenario)+"."+_vm._s(item.number)+" ")]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.result == 'PASS'
            ? 'color:green;'
            : item.result == 'FAIL'
            ? 'color:red;'
            : 'color:orange;')},[_vm._v(_vm._s(item.result))])]}},{key:"item.log_drs",fn:function(ref){
            var item = ref.item;
return [(item.log_drs != null)?_c('router-link',{attrs:{"to":{
          name: 'logfile-view',
          params: {
            type: 'drs',
            scenario: item.scenario,
            number: item.number,
            attempt: item.attempt,
          },
        }}},[_vm._v("OK")]):_vm._e()]}},{key:"item.video_ground",fn:function(ref){
        var item = ref.item;
return [(item.video_ground != null)?_c('router-link',{attrs:{"to":{
          name: 'video-view',
          params: {
            type: 'ground',
            scenario: item.scenario,
            number: item.number,
            attempt: item.attempt,
          },
        }}},[_vm._v(_vm._s(item.video_ground))]):_vm._e()]}},{key:"item.video_fpv",fn:function(ref){
        var item = ref.item;
return [(item.video_fpv != null)?_c('router-link',{attrs:{"to":{
          name: 'video-view',
          params: {
            type: 'fpv',
            scenario: item.scenario,
            number: item.number,
            attempt: item.attempt,
          },
        }}},[_vm._v(_vm._s(item.video_fpv))]):_vm._e()]}},{key:"item.video_onboard",fn:function(ref){
        var item = ref.item;
return [(item.video_onboard != null)?_c('router-link',{attrs:{"to":{
          name: 'video-view',
          params: {
            type: 'onboard',
            scenario: item.scenario,
            number: item.number,
            attempt: item.attempt,
          },
        }}},[_vm._v(_vm._s(item.video_onboard))]):_vm._e()]}},{key:"item.foto",fn:function(ref){
        var item = ref.item;
return [(item.foto != null)?_c('router-link',{attrs:{"to":{
          name: 'foto-view',
          params: {
            scenario: item.scenario,
            number: item.number,
            attempt: item.attempt,
          },
        }}},[_vm._v("YES")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-carousel height="800">
      <v-carousel-item
        v-for="(foto, i) in fotos"
        :key="i"
        :src="foto.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <!-- <v-btn
      @click="$router.go(-1)"
      class="mx-2"
      small
      style="width:50px;margin-top:10px;"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn> -->
  </div>
</template>

<script>
import api from "../plugins/api.js";

export default {
  name: "fotoview",
  props: ["scenario", "number", "attempt"],
  data() {
    return {
      title: "Foto viewer",
      fotos: [],
    };
  },
  mounted() {
    var _this = this;

    api
      .getFotoList(this.scenario, this.number, this.attempt)
      .then(function(result) {
        result.data.forEach(function(item) {
          _this.fotos.push({ src: api.getHost() + "/foto/" + item });
        });
      })
      .catch(function(error) {
        if (error.response.status == 401) {
          _this.$router.push({ path: "/login" });
        } else {
          console.log("ERROR getFotoList '" + error.message + "'");
        }
      });
  },
};
</script>

<style scoped>
.carousel {
  width: 100%;
}
</style>

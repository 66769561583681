const axios = require("axios").default;

const api_host = process.env.VUE_APP_ENDPOINT_HOST;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = api_host + "/api";
axios.defaults.crossdomain = true;

export default {
  getHost() {
    return api_host;
  },

  getFlightScenarios() {
    return axios.get("/scenarios", {
      headers: {
        "content-type": " application/json",
      },
    });
  },

  getFlightList() {
    return axios.get("/list", {
      headers: {
        "content-type": " application/json",
      },
    });
  },

  getFlightResults() {
    return axios.get("/results", {
      headers: {
        "content-type": " application/json",
      },
    });
  },

  getLogfile(type, scenario, number, attempt) {
    return axios.get("/log/" + type, {
      headers: {
        "content-type": " application/json",
      },
      params: {
        s: scenario,
        n: number,
        a: attempt,
      },
    });
  },

  getLogfileMarkers(type, scenario, number, attempt) {
    return axios.get("/log/" + type + "/marker", {
      headers: {
        "content-type": " application/json",
      },
      params: {
        s: scenario,
        n: number,
        a: attempt,
      },
    });
  },

  getFotoList(scenario, number, attempt) {
    return axios.get("/foto/list", {
      headers: {
        "content-type": " application/json",
      },
      params: {
        s: scenario,
        n: number,
        a: attempt,
      },
    });
  },

  requestLogin(pin) {
    return axios.post("/auth/login", {
      pwd: pin,
    });
  },

  requestLogout() {
    return axios.post("/auth/logout");
  },
};

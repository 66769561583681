<template>
  <v-app>
    <v-data-table
      :hide-default-footer="true"
      :headers="headers"
      :items="flights"
      :items-per-page="50"
      height="800"
      fixed-header
      item-key="sequence"
      class="flight-scenarios"
      group-by="category"
    >
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th colspan="14">
          <v-icon @click="toggle" style="margin-right:10px" text
            >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
          </v-icon>
          {{ items[0].category }}
        </th>
      </template>
      <template #item.scenario="{item}">
        {{ item.scenario }}.{{ item.number }}
      </template>
      <template #item.result="{item}">
        <span
          v-bind:style="
            item.result == 'PASS'
              ? 'color:green;'
              : item.result == 'FAIL'
              ? 'color:red;'
              : 'color:orange;'
          "
          >{{ item.result }}</span
        >
      </template>
      <template #item.log_drs="{item}">
        <router-link
          v-if="item.log_drs != null"
          :to="{
            name: 'logfile-view',
            params: {
              type: 'drs',
              scenario: item.scenario,
              number: item.number,
              attempt: item.attempt,
            },
          }"
          >OK</router-link
        >
      </template>
      <template #item.video_ground="{item}">
        <router-link
          v-if="item.video_ground != null"
          :to="{
            name: 'video-view',
            params: {
              type: 'ground',
              scenario: item.scenario,
              number: item.number,
              attempt: item.attempt,
            },
          }"
          >{{ item.video_ground }}</router-link
        >
      </template>
      <template #item.video_fpv="{item}">
        <router-link
          v-if="item.video_fpv != null"
          :to="{
            name: 'video-view',
            params: {
              type: 'fpv',
              scenario: item.scenario,
              number: item.number,
              attempt: item.attempt,
            },
          }"
          >{{ item.video_fpv }}</router-link
        >
      </template>
      <template #item.video_onboard="{item}">
        <router-link
          v-if="item.video_onboard != null"
          :to="{
            name: 'video-view',
            params: {
              type: 'onboard',
              scenario: item.scenario,
              number: item.number,
              attempt: item.attempt,
            },
          }"
          >{{ item.video_onboard }}</router-link
        >
      </template>
      <template #item.foto="{item}">
        <router-link
          v-if="item.foto != null"
          :to="{
            name: 'foto-view',
            params: {
              scenario: item.scenario,
              number: item.number,
              attempt: item.attempt,
            },
          }"
          >YES</router-link
        >
      </template>
    </v-data-table>
  </v-app>
</template>

<script>
import api from "../plugins/api.js";

export default {
  name: "flights",
  data() {
    return {
      flights: [],
      headers: [
        { text: "Scenario", value: "scenario" },
        { text: "Attempt", value: "attempt" },
        { text: "Trigger", value: "trigger" },
        { text: "TOW", value: "tow" },
        { text: "Delay [s]", value: "delay" },
        { text: "Seq", value: "sequence" },
        { text: "Result", value: "result" },
        { text: "Date", value: "date" },
        { text: "Time", value: "time" },
        // { text: "Report", value: "report" },
        { text: "Logfile DRS", value: "log_drs" },
        // { text: "Logfile DJI", value: "log_dji" },
        { text: "Video Ground", value: "video_ground" },
        { text: "Video FPV", value: "video_fpv" },
        { text: "Video OnBoard", value: "video_onboard" },
        { text: "Foto", value: "foto" },
      ],
      scenarios: [],
    };
  },
  mounted() {
    var _this = this;

    api
      .getFlightList()
      .then(function(res) {
        _this.flights = res.data;
      })
      .catch(function(error) {
        if (error.response.status == 401) {
          _this.$router.push({ path: "/login" });
        } else {
          console.log("ERROR getFlightList '" + error.message + "'");
        }
      });

    api
      .getFlightScenarios()
      .then(function(res) {
        _this.scenarios = res.data;
      })
      .catch(function(error) {
        console.log("getFlightScenarios - " + error.message);
      });
  },
};
</script>

<style scoped>
td {
  text-align: center;
}
.result_pass {
  color: green;
}
.result_fail {
  color: red;
}
.result_other {
  color: orange;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.7rem;
  height: 30px;
}
</style>

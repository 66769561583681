import Vue from "vue";
import VueRouter from "vue-router";

import FlightsView from "../views/flights.view.vue";
import LogView from "../views/logfile.view.vue";
import VideoView from "../views/video.view.vue";
import FotoView from "../views/foto.view.vue";
import ResultsView from "../views/results.view.vue";
import HelpView from "../views/about.view.vue";
import LoginView from "../views/login.view.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/flights",
    name: "flights-view",
    component: FlightsView,
  },
  {
    path: "/log/:type/:scenario/:number/:attempt",
    name: "logfile-view",
    component: LogView,
    props: true,
  },
  {
    path: "/video/:type/:scenario/:number/:attempt",
    name: "video-view",
    component: VideoView,
    props: true,
  },
  {
    path: "/foto/:scenario/:number/:attempt",
    name: "foto-view",
    component: FotoView,
    props: true,
  },
  {
    path: "/results",
    name: "results-view",
    component: ResultsView,
  },
  {
    path: "/about",
    name: "about-view",
    component: HelpView,
  },
  {
    path: "/login",
    name: "login-view",
    component: LoginView,
  },
  {
    // default route
    path: "*",
    redirect: "/flights",
  },
];

const router = new VueRouter({
  routes,
});

export default router;

<template>
  <div style="margin: 0 100px 0px 100px;">
    <template>
      <div class="text-h7 mb-1">Overall summary:</div>
      <v-simple-table style="padding: 0 100px 20px 100px;">
        <template v-slot:default>
          <thead style="background-color:#DBDBDB">
            <tr>
              <th></th>
              <th colspan="3" class="text-center">
                Descent rate<br />
                <span class="subheader font-weight-thin"
                  >In stable descent</span
                >
              </th>
              <th colspan="3" class="text-center">
                Drop height<br />
                <span class="subheader font-weight-thin"
                  >Between parachute deployed and stable descent reached</span
                >
              </th>
            </tr>
            <tr>
              <th>TOW</th>
              <th class="text-center">Minimum</th>
              <th class="text-center">Maximum</th>
              <th class="text-center">Average</th>
              <th class="text-center">Minimum</th>
              <th class="text-center">Maximum</th>
              <th class="text-center">Average</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>6.7 kg (Minimum)</td>
              <td class="text-center">
                {{ summary.mintow.descent_rate_min }} m/s
              </td>
              <td class="text-center">
                {{ summary.mintow.descent_rate_max }} m/s
              </td>
              <td class="text-center">
                {{ summary.mintow.descent_rate_avg }} m/s
              </td>
              <td class="text-center">
                {{ summary.mintow.drop_height_min }} m
              </td>
              <td class="text-center">
                {{ summary.mintow.drop_height_max }} m
              </td>
              <td class="text-center">
                {{ summary.mintow.drop_height_avg }} m
              </td>
            </tr>
            <tr>
              <td>9.7 kg (Maximum)</td>
              <td class="text-center">
                {{ summary.maxtow.descent_rate_min }} m/s
              </td>
              <td class="text-center">
                {{ summary.maxtow.descent_rate_max }} m/s
              </td>
              <td class="text-center">
                {{ summary.maxtow.descent_rate_avg }} m/s
              </td>
              <td class="text-center">
                {{ summary.maxtow.drop_height_min }} m
              </td>
              <td class="text-center">
                {{ summary.maxtow.drop_height_max }} m
              </td>
              <td class="text-center">
                {{ summary.maxtow.drop_height_avg }} m
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>

    <div v-for="idx in 8" :key="idx">
      <div class="text-h7 mb-1">{{ results[idx - 1].name }}:</div>
      <v-simple-table style="padding: 0 100px 20px 100px;">
        <template v-slot:default>
          <thead style="background-color:#DBDBDB">
            <tr>
              <th class="text-center">
                Scenario
              </th>
              <th colspan="2" class="text-center">
                Failure detected<br />
                <span class="subheader font-weight-thin"
                  >Between failure initiated and parachute deployed</span
                >
              </th>
              <th colspan="2" class="text-center">
                Parachute open<br />
                <span class="subheader font-weight-thin"
                  >Between parachute deployed and inflated</span
                >
              </th>
              <th colspan="2" class="text-center">
                Stable descent<br />
                <span class="subheader font-weight-thin"
                  >Between parachute inflated and stable descent reached</span
                >
              </th>
              <th class="text-center">
                Descent rate<br />
                <span class="subheader font-weight-thin"
                  >In stable descent</span
                >
              </th>
            </tr>
            <tr>
              <td class="text-center">Number</td>
              <td class="text-center">Time</td>
              <td class="text-center">Altitude</td>
              <td class="text-center">Time</td>
              <td class="text-center">Altitude</td>
              <td class="text-center">Time</td>
              <td class="text-center">Altitude</td>
              <td class="text-center">Velocity</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in results[idx - 1].data" :key="item.marker">
              <td class="text-center">
                <router-link
                  :to="{
                    path: '/log',
                    params: {
                      type: 'drs',
                      scenario: item.scenario,
                      number: item.number,
                      attempt: item.attempt,
                    },
                  }"
                >
                  {{ item.scenario }}.{{ item.number }}.{{ item.attempt }}
                </router-link>
              </td>
              <td class="text-center">{{ item.result.failure_time }} s</td>
              <td class="text-center">{{ item.result.failure_alti }} m</td>
              <td class="text-center">{{ item.result.open_time }} s</td>
              <td class="text-center">{{ item.result.open_alti }} m</td>
              <td class="text-center">{{ item.result.stable_time }} s</td>
              <td class="text-center">{{ item.result.stable_alti }} m</td>
              <td class="text-center">{{ item.result.descent_rate }} m/s</td>
            </tr>
          </tbody>
          <tfoot style="background-color:#DBDBDB">
            <tr>
              <td class="text-center">average:</td>
              <td class="text-center">
                {{ results[idx - 1].statistic.failure_time }} s
              </td>
              <td class="text-center">
                {{ results[idx - 1].statistic.failure_alti }} m
              </td>
              <td class="text-center">
                {{ results[idx - 1].statistic.open_time }} s
              </td>
              <td class="text-center">
                {{ results[idx - 1].statistic.open_alti }} m
              </td>
              <td class="text-center">
                {{ results[idx - 1].statistic.stable_time }} s
              </td>
              <td class="text-center">
                {{ results[idx - 1].statistic.stable_alti }} m
              </td>
              <td class="text-center">
                {{ results[idx - 1].statistic.descent_rate }} m/s
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import api from "../plugins/api.js";

export default {
  name: "results-view",
  data() {
    return {
      title: "TODO: Outcome of the test",
      results: null,
      summary: {
        mintow: {
          descent_rate_avg: 0,
          descent_rate_max: 0,
          descent_rate_min: 99,
          drop_height_avg: 0,
          drop_height_max: 0,
          drop_height_min: 99,
        },
        maxtow: {
          descent_rate_avg: 0,
          descent_rate_max: 0,
          descent_rate_min: 99,
          drop_height_avg: 0,
          drop_height_max: 0,
          drop_height_min: 99,
        },
      },
    };
  },
  methods: {
    calcSummary: function() {
      [0, 2, 4, 6].forEach((res) => {
        // Loop through MinTOW entries
        // Descent rate ---
        var value = this.results[res].statistic.descent_rate;
        this.summary.mintow.descent_rate_avg += parseFloat(value);

        if (value > this.summary.mintow.descent_rate_max) {
          // Update max value for descent rate
          this.summary.mintow.descent_rate_max = value;
        }

        if (value < this.summary.mintow.descent_rate_min) {
          // Update min value for descent rate
          this.summary.mintow.descent_rate_min = value;
        }

        // Drop height ----
        value =
          parseFloat(this.results[res].statistic.open_alti) +
          parseFloat(this.results[res].statistic.stable_alti);
        this.summary.mintow.drop_height_avg += value;

        if (value > this.summary.mintow.drop_height_max) {
          // Update max value for drop height
          this.summary.mintow.drop_height_max = value;
        }

        if (value < this.summary.mintow.drop_height_min) {
          // Update min value for drop height
          this.summary.mintow.drop_height_min = value;
        }

        // Calculate averages
        if (res == 6) {
          this.summary.mintow.descent_rate_avg =
            Math.round((this.summary.mintow.descent_rate_avg / 4) * 100) / 100;

          this.summary.mintow.drop_height_avg =
            Math.round((this.summary.mintow.drop_height_avg / 4) * 100) / 100;
        }
      });

      [1, 3, 5, 7].forEach((res) => {
        // Loop through MinTOW entries
        // Descent rate ---
        var value = this.results[res].statistic.descent_rate;
        this.summary.maxtow.descent_rate_avg += parseFloat(value);

        if (value > this.summary.maxtow.descent_rate_max) {
          // Update max value for descent rate
          this.summary.maxtow.descent_rate_max = value;
        }

        if (value < this.summary.maxtow.descent_rate_min) {
          // Update min value for descent rate
          this.summary.maxtow.descent_rate_min = value;
        }

        // Drop height ----
        value =
          parseFloat(this.results[res].statistic.open_alti) +
          parseFloat(this.results[res].statistic.stable_alti);
        this.summary.maxtow.drop_height_avg += value;

        if (value > this.summary.maxtow.drop_height_max) {
          // Update max value for drop height
          this.summary.maxtow.drop_height_max = value;
        }

        if (
          this.results[res].statistic.stable_alti <
          this.summary.maxtow.drop_height_min
        ) {
          // Update min value for drop height
          this.summary.maxtow.drop_height_min = this.results[
            res
          ].statistic.stable_alti;
        }

        // Calculate averages
        if (res == 7) {
          this.summary.maxtow.descent_rate_avg =
            Math.round((this.summary.maxtow.descent_rate_avg / 4) * 100) / 100;

          this.summary.maxtow.drop_height_avg =
            Math.round((this.summary.maxtow.drop_height_avg / 4) * 100) / 100;
        }
      });
    },
  },
  mounted() {
    var _this = this;
    api
      .getFlightResults()
      .then(function(res) {
        _this.results = res.data;
        _this.calcSummary();
      })
      .catch(function(error) {
        if (error.response.status == 401) {
          _this.$router.push({ path: "/login" });
        } else {
          console.log("ERROR getFlightResults '" + error.message + "'");
        }
      });
  },
};
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.7rem;
  height: 30px;
}

.subheader {
  font-size: smaller;
  color: #929292;
}
</style>

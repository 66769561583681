<template>
  <div color="white" elevation="1" height="100" width="100">
    <v-chart class="chart" ref="echart" :option="chart_option" />
    <!-- <center class="message">{{ message }}</center> -->
  </div>
</template>

<script>
import api from "../plugins/api.js";

export default {
  name: "logview",
  props: ["type", "scenario", "number", "attempt"],
  data() {
    return {
      scenario_name: [
        "Hover - Full power cut",
        "Hover - CNMF Failure",
        "Full forward speed - Full power cut",
        "Full forward speed - CNMF Failure",
        "Shock load testing",
      ],
      scenario_types: [
        "Manual @ MinTOW",
        "Manual @ MaxTOW",
        "Automatic @ MinTOW",
        "Automatic @ MaxTOW",
      ],
      message: "",
      chart_message: [
        {
          type: "group",
          right: "10%",
          top: "8%",
          children: [
            {
              type: "rect",
              z: 100,
              left: "center",
              top: "middle",
              shape: {
                width: 320,
                height: 110,
              },
              style: {
                fill: "#fff",
              },
            },
          ],
        },
      ],
      chart_option: {
        animation: false,
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          show: true,
          left: "center",
          top: 20,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            saveAsImage: {
              backgroundColor: "white",
            },
          },
        },
        xAxis: {
          name: "Time",
          nameLocation: "middle",
          nameGap: 30,
          axisLabel: {
            formatter: "{value}s",
          },
        },
        yAxis: [
          {
            name: "Altitude",
            min: 0,
            axisLabel: {
              formatter: "{value}m",
            },
          },
          {
            name: "Velocity",
            axisLabel: {
              formatter: "{value}m/s",
            },
          },
        ],
        title: {
          left: "center",
          text: "",
        },
        dataset: {
          source: [],
        },
        graphic: [],
        series: [
          {
            name: "Altitude",
            type: "line",
            yAxisIndex: 0,
            symbol: "none",
            zlevel: 0,
          },
          {
            name: "Velocity",
            type: "line",
            yAxisIndex: 1,
            symbol: "none",
            zlevel: -1,
            encode: {
              y: 2,
            },
          },
        ],
      },
    };
  },
  mounted() {
    var _this = this;

    api
      .getLogfile(this.type, this.scenario, this.number, this.attempt)
      .then(function(result) {
        _this.$refs.echart
          .setOption({
            dataset: {
              source: result.data,
            },
            title: {
              text:
                _this.scenario +
                "." +
                _this.number +
                "." +
                _this.attempt +
                " - " +
                _this.scenario_name[_this.scenario - 1] +
                " - " +
                (_this.scenario === 5
                  ? _this.scenario_types[1]
                  : _this.scenario_types[_this.number - 1]),
            },
          })
          .catch(function(error) {
            if (error.response.status == 401) {
              _this.$router.push({ path: "/login" });
            } else {
              console.log("ERROR getLogfile '" + error.message + "'");
            }
          });
      });

    api
      .getLogfileMarkers(this.type, this.scenario, this.number, this.attempt)
      .then(function(result) {
        // console.log(result.data);
        var marker = { symbol: "circle", symbolSize: 20, data: [] };

        result.data.forEach((item, idx) => {
          // Loop through all markers
          if (item.x != 0) {
            marker.data.push({
              value: item.value,
              coord: [item.x, item.y],
            });

            // update messagebox
            _this.message +=
              item.value +
              " " +
              item.description +
              " " +
              item.x +
              "s " +
              item.y +
              "m\n";
          }

          if (idx === result.data.length - 1) {
            // Last element

            _this.chart_message[0].children.push({
              type: "text",
              z: 100,
              left: "center",
              top: "middle",
              style: {
                fill: "#333",
                text: _this.message.substring(0, _this.message.length - 1),
                color: "#efefef",
                font: "14px courier",
              },
            });

            _this.$refs.echart.setOption({
              series: { markPoint: marker },
              graphic: _this.chart_message,
            });
          }
        });
      });
  },
};
</script>

<style scoped>
.chart {
  height: 800px;
  margin: 20px;
  background-color: white;
}

.message {
  margin-left: 20px;
  color: dimgrey;
}
</style>

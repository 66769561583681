<template>
  <v-app style="with:100%;">
    <video class="videoPlayer" height="800px" controls autoplay>
      <source v-bind:src="getVideoUrl()" type="video/mp4" />
    </video>

    <v-btn
      @click="$router.go(-1)"
      class="mx-2"
      color="darkblue"
      style="width:50px;margin-left: 50px;"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
export default {
  name: "videoview",
  props: ["type", "scenario", "number", "attempt"],
  data() {
    return {
      title: "Video viewer",
    };
  },
  methods: {
    getVideoUrl: function() {
      return (
        process.env.VUE_APP_ENDPOINT_HOST +
        "/api/video/" +
        this.$props.type +
        "?s=" +
        this.$props.scenario +
        "&n=" +
        this.$props.number +
        "&a=" +
        this.$props.attempt
      );
    },
  },
};
</script>

<style scoped>
.videoPlayer {
  border: 2px solid white;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
</style>

<template>
  <v-app id="app" class="app">
    <v-card
      class="no-print"
      v-show="!showLogin()"
      color="grey lighten-4"
      flat
      height="50px"
      tile
    >
      <v-toolbar dense class="toolbar">
        <v-app-bar-nav-icon text
          ><img class="logo" src="./assets/drs_logo.png"
        /></v-app-bar-nav-icon>

        <v-toolbar-title class="d-flex flex-row title">{{
          appTitle
        }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn v-for="item in menu" :key="item.icon" :to="item.path" text>{{
          item.title
        }}</v-btn>
      </v-toolbar>
    </v-card>

    <v-main class="main">
      <router-view />
    </v-main>

    <v-footer app class="no-print">
      <span class="d-flex justify-start footer">
        &#169; Drone Rescue Systems GmbH, 2021
        <a href="http://www.dronerescue.com" style="padding-left:10px"
          >www.dronerescue.com</a
        >
      </span>
      <v-spacer></v-spacer>
      <v-btn elevation="2" x-small v-on:click="logout">LOGOUT</v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import api from "./plugins/api.js";

export default {
  data() {
    return {
      appTitle: "DRS-M300 :: ASTM F3322-18",
      menu: [
        { title: "Flights", path: "/flights" },
        { title: "Results", path: "/results" },
        { title: "About", path: "/about" },
      ],
    };
  },
  methods: {
    showLogin: function() {
      return this.$route.name == "login-view";
      // return this.$router.history.current["path"] !== "/user/config";
    },
    logout: function() {
      api.requestLogout().then(() => {
        this.$router.push({ path: "/login" });
      });
    },
  },
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.app {
  overflow: hidden;
}
.toolbar {
  position: fixed;
  width: 100%;
  z-index: 10;
}

.logo {
  margin-left: 20px;
}

.title {
  margin-left: 20px;
}

.main {
  margin: 20px;
}

.footer {
  font-size: smaller;
  color: #6d6d6d;
}

@media print {
  .main {
    margin: 0;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>

<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <span class="text-h6 mb-2">Information shown on this site:</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col offset="1" cols="10">
        The purpose of this site is to show the flights performed within the
        testing of the DRS-M300 parachute recovery system according to the
        <a href="https://www.astm.org/Standards/F3322.htm">ASTM F3322-18</a>
        standard. The standard defines 45 test scenarios which need to be
        accomplished. To get the overall performance of the parachute rescue
        system (like minimum deployable altitude or descent rate) data of each
        flight must get recorded and analysed at the end.
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <span class="text-h6 mb-2">DRS Logfile:</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col offset="1" cols="10">
        When flying the UAV at the desired altitude a failure must be injected
        to disrupt the regular flying. The moment this failure was initiation is
        marked with the number <span class="circled">&#9313;</span> in the
        altitude charts. After the failure initiation the UAV will start falling
        until marker number <span class="circled">&#9314;</span> which is the
        moment the parachute rescue system detected the malfunction and
        triggered the parachute. This time periode between
        <span class="circled">&#9313;</span> and
        <span class="circled">&#9314;</span> is called "<span style="color:red;"
          >Failure time</span
        >". After the parachute got deployed it takes some time to fully inflate
        the canopy, which is called "<span style="color:#008080;"
          >Open time</span
        >" and its end is marked with the number
        <span class="circled">&#9315;</span>. When the parachute is fully opened
        it takes the time called "<span style="color:#FF6600;">Stable time</span
        >" until the parachute reached it's stable descent velocity. The end of
        this periode is marked with number
        <span class="circled">&#9316;</span> and this is when the stable "<span
          style="color:#803300;"
          >Descent rate</span
        >" is reached. The descent rate is calculated between marker
        <span class="circled">&#9316;</span> and marker
        <span class="circled">&#9317;</span> which is the moment the aircraft
        landed.
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col offset="2" cols="8">
        <img class="chart" src="../assets/explanation.svg" />
      </v-col>
    </v-row>
    <v-row no-gutters> </v-row>
  </v-container>
</template>

<script>
// import api from "../plugins/api.js";

export default {
  name: "help-view",
  data() {
    return {
      title: "TODO: Description what's shown on this site",
    };
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
}
.circled {
  font-size: 120%;
  color: #768cd1;
}
</style>
